// Motovun Jack - TiddilyWiki logo
// By Jeremy Ruston - Jermolene/TiddlyWiki5 at Github, BSD, https://commons.wikimedia.org/w/index.php?curid=50495101
import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="23px"
      viewBox="34 130 510 317"
    >
      <path
        fill="#FFF"
        d="M204.103 372.673l2.81.829c3.532-1.58 10.63.862 14.396 2.056-6.934-7.687-17.38-18.975-24.907-26.091-2.47-8.616-1.416-17.285-.885-26.08l.107-.728c-2.967 7.04-6.732 13.885-8.755 21.296-2.368 9.998 10.524 20.785 15.523 26.968zm214.9 42.285c-19.35-.547-27.861-.5-37.716-16.705l-7.68.22c-8.94-.397-5.214-.217-11.179-.514-9.971-.388-8.374-9.863-10.128-14.869-2.807-16.993 3.713-34.074 3.508-51.07-.073-6.034-8.61-27.39-11.66-35.025-9.562 1.8-19.176 2.144-28.876 2.627-22.36-.054-44.567-2.793-66.614-6.27-4.296 17.749-17.067 42.59-6.511 60.627 12.813 18.658 21.804 23.827 35.741 24.952 13.937 1.124 17.084 16.859 13.712 22.479-2.984 3.884-8.23 4.589-12.686 5.53l-8.915.419c-7.014-.236-13.284-2.13-19.535-5.051-10.439-5.827-21.22-17.941-29.23-26.638 1.865 3.473 2.977 10.253 1.286 13.408-4.736 6.709-25.219 6.668-34.6 2.499-10.655-4.735-36.404-37.982-40.385-62.882 10.59-20.03 26.02-37.475 33.826-59.284-17.015-10.857-26.128-28.53-24.945-48.551l.427-2.318c-16.742 3.134-8.06 1.968-26.07 3.33-57.356-.175-107.796-39.064-79.394-99.505 1.847-3.579 3.604-6.833 6.735-5.279 2.513 1.247 2.152 6.249.888 11.796-16.235 72.219 63.112 72.772 111.888 59.408 4.84-1.327 14.469-10.261 21.138-13.223 10.902-4.841 22.735-6.806 34.478-8.22 29.208-3.329 64.312 12.057 82.148 12.56 17.837.502 43.21-4.27 58.785-3.266 11.302.517 22.399 2.557 33.303 5.463 7.058-34.391 7.557-59.738 24.289-65.606 9.82 1.551 17.387 14.933 22.988 22.083l.023-.004c11.407-.45 22.262 2.444 33.055 5.656 19.54-2.773 35.937-13.746 53.193-22.282-.054.269-.336.36-.504.541-16.982 13.734-19.354 36.958-17.356 58.434.743 11.144-2.406 23.244-6.299 34.654-7.285 18.59-21.354 38.185-37.683 37.18-6.173-.196-9.76-3.69-14.347-7.423-.89 7.56-4.388 14.304-7.76 20.989-7.787 14.821-28.136 21.353-46.978 37.18-18.84 15.827 6.029 72.351 12.059 82.65 6.029 10.3 22.86 14.068 16.329 23.364-1.998 3.07-5.053 4.168-8.318 5.359z"
      ></path>
    </svg>
  );
}